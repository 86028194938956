import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import {Button, Grid, Link, Paper} from "@mui/material";
import {Image} from "mui-image";
import {Route, Routes, useNavigate} from "react-router-dom";
import Campaign from "./Campaign";
import {makeStyles} from "@mui/styles";
import ContactUs from "./ContactUs";
import MyDonations from "./MyDonations";
import ThankYou from "./ThankYou";
import PrepareCheckOut from "./PrepareCheckOut";
import GetUserInfo from "./GetUserInfo";
import {Close} from "@mui/icons-material";
import CountrySettings from "./CountrySettings";
import GiftMyDonation from "./GiftMyDonation";


const drawerWidth = 340;
export const useStylesLandingPage = makeStyles((theme) => ({
    root: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            width: "500px",

        },
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        }
    },

    mainContentWraper: {

        [theme.breakpoints.up("md")]: {
            width: "500px",

        },
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        }
    },


    leftMenu: {
        backgroundColor: '#FFFFFF'
    },
    bottomPush: {
        clear: "both",
        textAlign: "center",
        padding: 10,


    }
}));

function LandingPage(props) {
    const navigate = useNavigate();
    const classes = useStylesLandingPage();
    let appData = props.appData
    const {win} = props;
    const container = win !== undefined ? () => window().document.body : undefined;

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [selectedCampaign, setSelectedCampaign] = React.useState(null);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div style={{minHeight: "100vh"}}>
            <div style={{minHeight: "90vh"}}>
                <Close style={{marginLeft: 300, marginTop: 10}}
                       onClick={(event) =>
                           handleDrawerToggle()
                       }
                />
                <br/>
                <br/>
                <Grid container xs={12}>
                    <Grid item xs={6}>
                        <Image src="/image/applestore.png" style={{
                            cursor: "pointer",
                            float: 'none',
                            width: 150,
                            resizeMode: 'contain',
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                               onClick={() => {
                                   window.open("https://apps.apple.com/us/app/al-ayn/id1424196412", '_blank');
                               }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Image src="/image/gstore.png" style={{
                            cursor: "pointer",
                            float: 'none',
                            width: 150,
                            resizeMode: 'contain',
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                               onClick={() => {
                                   window.open("https://play.google.com/store/apps/details?id=com.tis.alayn&hl=en&gl=US&pli=1", '_blank');
                               }}
                        />
                    </Grid>
                </Grid>

                <List className={classes.leftMenu}>
                    <ListItem
                        style={{cursor: "pointer", width: '340px', padding: 20}}
                        component={Link}
                        onClick={() => {
                            navigate("/")
                            handleDrawerToggle()
                        }}
                    >
                        <img src='/image/icon_home.png' style={{width: "20px"}}/>
                        &nbsp; &nbsp;
                        <b>{appData.menuHome}</b>
                    </ListItem>
                    <ListItem
                        style={{cursor: "pointer", width: '340px', padding: 20}}
                        component={Link}
                        onClick={() => {
                            navigate("/mydonations")
                            handleDrawerToggle()
                        }}
                    >
                        <img src='/image/icon_donations.png' style={{width: "20px"}}/>
                        &nbsp; &nbsp;
                        <b>{appData.menuMyDonation}</b>
                    </ListItem>
                    <ListItem
                        style={{cursor: "pointer", width: '340px', padding: 20}}
                        component={Link}
                        onClick={() => {
                            navigate("/changeCountry")
                            handleDrawerToggle()
                        }}
                    >
                        <img src='/image/lang_contry.png' style={{width: "20px"}}/>
                        &nbsp; &nbsp;
                        <b>{appData.menuCountryLanguage}</b>
                    </ListItem>
                    <ListItem
                        style={{cursor: "pointer", width: '340px', padding: 20}}
                        component={Link}
                        onClick={() => {
                            navigate("/contactus")
                            handleDrawerToggle()
                        }}
                    >
                        <img src='/image/icon_contactus.png' style={{width: "20px"}}/>
                        &nbsp; &nbsp;
                        <b> {appData.menuContactUs}</b>
                    </ListItem>
                </List>
            </div>
            <div>
                <ListItem
                    style={{cursor: "pointer", width: '340px', padding: 10}}
                    component={Link}
                    onClick={(event) =>
                        window.open("http://www.ecnetsolutions.ca", '_blank')}>
                    <Image src="/image/ecnet_logo.png" style={{width: "220px"}}
                    />
                </ListItem>
            </div>
        </div>
    );

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar
                elevation={0}
                color={"transparent"}
                style={{background: "#FFFFFF"}}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}

                        style={{position: "absolute"}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Image src="/image/header_logo.png" style={{
                        float: 'none',
                        width: '130px',
                        marginLeft: 'auto',
                        marginRight: 'auto',

                    }}/>
                </Toolbar>
            </AppBar>
            <CssBaseline/>
            <Paper
                sx={{width: {sm: 0}, flexShrink: {sm: 0}}}
                aria-label="mailbox folders"
            >
                <Drawer
                    style={{backgroundColor: "#FFFFF"}}
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: {xs: 'block', sm: 'block'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                >
                    {drawer}
                </Drawer>
            </Paper>
            <Grid
                container
                spacing={0}
                justifyContent={"center"}
                style={{minHeight: "90vh"}}
            >
                <Box
                    className={classes.mainContentWraper}
                >
                    <Toolbar/>
                    <Routes>
                        <Route path="/" exact element={<HomePage props={props} appData={appData} setSelectedCampaign={setSelectedCampaign}/>}/>
                        <Route path="/campaign" exact
                               element={<Campaign appData={appData} selectedCampaign={selectedCampaign}/>}/>
                        <Route path="/mydonations" exact element={<MyDonations appData={appData}/>}/>
                        <Route path="/changeCountry" exact element={<CountrySettings appData={appData}/>}/>
                        <Route path="/contactus" exact element={<ContactUs appData={appData}/>}/>
                        <Route path="/checkout" exact element={<PrepareCheckOut/>}/>
                        <Route path="/getuserinfo" exact element={<GetUserInfo appData={appData}/>}/>
                        <Route path="/thankyou" exact element={<ThankYou/>}/>
                        <Route path="/giftmydonation" exact element={<GiftMyDonation appData={appData}/>}/>
                    </Routes>
                </Box>
            </Grid>
        </Box>
    );
}

LandingPage.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default LandingPage;

function HomePage({props, appData, setSelectedCampaign}) {
    const navigate = useNavigate()
    return (
        <Box>
             <div style={{
            width: "100%",
            position: "relative"
        }}>
                <Toolbar/>
                <Box style={{
                    backgroundImage: 'url(/image/home_background.png)',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundRepeat: "no-repeat",
                    backgroundPositionX: "center",
                
                }}>
                    
                    <Box style={{height: '55vh',}}></Box>
                    <Grid container style={{flex: 'none'}} p={5} justifyContent="center"
                          alignItems="center">
                        <Button variant="outlined" color="white" style={{
                            width: '100%',
                            height: 50,
                            color: "#FFF",
                            fontSize: 16,
                            lineHeight: 1,
                            fontWeight: "bold"
                        }}
                                onClick={() => {
                                    setSelectedCampaign(appData.homeOptionOne)
                                    navigate("/campaign")
                                }

                                }
                        >{appData.homeOptionOne}</Button>
                        <Button variant="outlined" color="white" style={{
                            width: '100%',
                            height: 50,
                            marginTop: 10,
                            color: "#FFF",
                            fontSize: 16,
                            lineHeight: 1,
                            fontWeight: "bold"
                        }}
                                onClick={() => {
                                    setSelectedCampaign(appData.homeOptionTwo)
                                    navigate("/campaign")
                                }
                                }

                        >{appData.homeOptionTwo}</Button>
                        <Button variant="outlined" color="white" style={{
                            width: '100%',
                            height: 50,
                            marginTop: 10,
                            color: "#FFF",
                            fontSize: 16,
                            lineHeight: 1,
                            fontWeight: "bold"
                        }}
                                onClick={() => {
                                    setSelectedCampaign(appData.homeOptionThree)
                                    navigate("/campaign")
                                }
                                }
                        >{appData.homeOptionThree}</Button>

                    </Grid>
                </Box>

            </div>
        </Box>

    );
}