import * as React from 'react';
import Box from "@mui/material/Box";
import Image from "mui-image";
import {Button, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select} from "@mui/material";
import {useEffect, useState} from "react";
import ApiRouter from "./api/ApiRouter";
import ProgressLoader from "./widgets/ProgressLoader";

export default function CountrySettingsStart() {
    const [countriesList, setCountriesList] = React.useState(null);
    const [selectedCountry, setSelectedCountry] = React.useState(null);
    const [selectedLanguage, setSelectedLanguage] = React.useState(null);

    const handleChangeCountry = (event) => {
        // storing input
        localStorage.setItem("countryId", JSON.stringify(event.target.value));
        setSelectedCountry(event.target.value);
    };

    const handleChangeLanguage = (event) => {
        // storing input
        localStorage.setItem("languageId", JSON.stringify(event.target.value));
        setSelectedLanguage(event.target.value);

    };

    useEffect(
        function effectFunction() {
            const method = "api/listcountriesandlanguages";
            ApiRouter.fetchDataPost(method, [], (response) => {
                setCountriesList(response)
                console.log(response, "MyDonations")

            }, (err) => {
                //error
                console.log("error", "MyDonations")
            });

        },
        []
    )
    const handleSubmit = () => {
        window.location.reload(false);
    }
    return (
        <Grid
        container
        spacing={0}
      
        justifyContent={"center"}
        
    >
          {countriesList == null ? <ProgressLoader/> :
        <Grid container justify="center" spacing={3} p={5}  maxWidth="sm">
                    <Grid item xs={12}>
                    <img src='/image/artwork.png' style={{width: "200px"}}/>
                        </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="country-select-label"> Country</InputLabel>
                            <Select
                                labelId="country-select-label"
                                id="country-select"
                                value={selectedCountry}
                                label="Country"
                                input={<OutlinedInput label="Country"/>}
                                onChange={handleChangeCountry}
                            >
                                {countriesList?.data.map(item => {
                                    return <MenuItem value={item}>{item.name}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="language-select-label">Language</InputLabel>
                            <Select
                                labelId="language-select-label"
                                id="language-select"
                                value={selectedLanguage}
                                label="Language"
                                input={<OutlinedInput label="Language"/>}
                                onChange={handleChangeLanguage}
                            >
                                {selectedCountry?.languages?.map(item => {
                                    return <MenuItem value={item}>{item.name}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Button variant="contained" onClick={handleSubmit} style={{
                                width: '100%',
                                height: 50,
                                marginTop: 10,
                                color: "#FFF",
                                fontSize: 12,
                                lineHeight: 1,
                                fontWeight: "bold"
                            }}>
                               Continue
                            </Button>
                        </FormControl>
                    </Grid>
            
        </Grid>}
        </Grid>
    )
        ;
}