import * as React from 'react';
import { useState } from 'react';
import { Button, FormControlLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ApiRouter from "./api/ApiRouter";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import { Image } from "mui-image";
import CssBaseline from "@mui/material/CssBaseline";
import ProgressLoader from "./widgets/ProgressLoader";

export default function GiftMyDonation({ appData,donationid }) {
    const navigate = useNavigate();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [radioBtnValue, setRadioBtnValue] = React.useState("");
    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [houseNumber, setHouseNumber] = useState("");
    const [postCode, setPostCode] = useState("");
    const [successMessage, setSuccessMessage] = useState(null);

    const onTextChangeFirstName = (e) => setFirstName(e.target.value);
    const onTextChangeLastName = (e) => setLastName(e.target.value);
    const onTextChangeEmail = (e) => setHouseNumber(e.target.value);
    const onTextChangePhone = (e) => setPostCode(e.target.value);
    const handleRadioChange = (e) => {
        setRadioBtnValue(e.target.value);
    };


    const handleSubmit = () => {
        setIsSubmitted(true)
        if (houseNumber === "" || postCode === "" || firstname === "" || lastname === "" || radioBtnValue === "") {
            alert(appData.errorGiftAidBox)
            setIsSubmitted(false)
        } else {
            const method = "/api/addgiftboxinfo";
            const formData = new FormData();
            formData.append('donationid', donationid)
            formData.append("firstname", firstname)
            formData.append("surname", lastname)
            formData.append("email", "")
            formData.append("house", houseNumber)
            formData.append("pincode", postCode)

            ApiRouter.fetchDataPost(method, formData, (response) => {
                console.log("addgiftboxinfo", response)
                setSuccessMessage(response.message)
            },
                (err) => {
                    //error
                    setIsSubmitted(false)
                    console.log("error", "addgiftboxinfo")
                });
        }
    }

    return (
        <>
            <AppBar
                elevation={0}
                color={"transparent"}
                style={{ background: "#FFFFFF" }}
            >
                <Toolbar>
                    <Image src="/image/header_logo.png" style={{
                        float: 'none',
                        width: '130px',
                        marginLeft: 'auto',
                        marginRight: 'auto',

                    }} />
                </Toolbar>
            </AppBar>
            <CssBaseline />
            <div>
                <b>{appData?.titleGiftAid}</b>
            </div>
            <Toolbar />

            <Grid
                container
                direction="column"
                alignItems="center"
                justify="center"
            >
                {successMessage != null ? <>
                    <Grid item xs={12}>
                                <Box>
                                    <img src='/image/home_banner.png' style={{ width: "100%" }} />
                                </Box>
                            </Grid>
                    <Grid item xs={12} p={3}>
                        <Typography>
                            {successMessage}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} p={3}>
                        <Button fullWidth variant="contained" onClick={() =>
                            window.location = window.location.pathname
                        }
                            style={{ color: "white" }}>{appData.buttonContinue}</Button>

                    </Grid>
                </> :
                    (isSubmitted ? <ProgressLoader /> :
                        <Grid container xs={12} spacing={3} md={6}>
                            <Grid item xs={12}>
                                <Box>
                                    <img src='/image/home_banner.png' style={{ width: "100%" }} />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    Add 25% more to your donation at no cost to you. A Gift Aid
                                    declaration allows Al-Ayn Social Care Foundation to claim tax back on
                                    eligible donations. It means that for every £1 you donate to Al-Ayn
                                    Social Care Foundation we can claim back 25p, at no extra cost to you.
                                </Typography>

                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    onChange={onTextChangeFirstName}
                                    value={firstname}
                                    error={(firstname === "") && isSubmitted}
                                    id="outlined-basic firstname" label={appData.placeholderFirstName} variant="outlined" />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    onChange={onTextChangeLastName}
                                    value={lastname}
                                    error={(lastname === "") && isSubmitted}
                                    id="outlined-basic lastname" label={appData.placeholderSurname} variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    onChange={onTextChangeEmail}
                                    value={houseNumber}
                                    error={(houseNumber === "") && isSubmitted}
                                    id="outlined-basic email" label={appData.placeholderHouseNumber} variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    onChange={onTextChangePhone}
                                    value={postCode}
                                    error={(postCode === "") && isSubmitted}
                                    id="outlined-basic phone" label={appData.placeholderPostalCode} variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <RadioGroup
                                    aria-labelledby="radio buttons"
                                    name="quiz"
                                    value={radioBtnValue}
                                    onChange={handleRadioChange}
                                >
                                    <FormControlLabel value={appData.checkboxTitle} control={<Radio />}
                                        label={appData.checkboxTitle} />
                                </RadioGroup>
                            </Grid>
                            <Grid item xs={12} p={3}>
                                <Button fullWidth variant="contained" onClick={handleSubmit}
                                    style={{ color: "white" }}>{appData.buttonSubmit}</Button>

                            </Grid>
                            <Grid item xs={12} p={3}>
                                <Button style={{ color: "white" }} fullWidth variant="contained" onClick={() =>
                                    window.location = window.location.pathname
                                } >{appData.buttonCancel}</Button>
                            </Grid>
                        </Grid>)}
            </Grid>
        </>
    );

}