import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import Image from "mui-image";
import {Button, Grid, TextField} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {ReferenceDataContext} from "./utils/ReferenceDataContext";

export default function GetUserInfo({appData}) {

    const { state } = useLocation();
    const amount = state.amount
    const currency = state.currency
    const currencyCode = appData.currency
    const typeOfDonation = state.typeOfDonation
    const pk = state.pk
    const sk = state.sk
    // const pk = "pk_test_lL5rMBPPHSZ4BShkaAzW5CXr"
    // const sk = "sk_test_Wq0JqQYz8vZwED3I3FpiH7XF"

    const {sessionData, setSessionData} = useContext(ReferenceDataContext)
    const navigate = useNavigate();

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [firstname, setFirstName] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("firstname");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
    });

    const [lastname, setLastName] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("lastname");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
    });
    const [emailInput, setEmailInput] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("emailInput");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
    });
    const [phone, setPhone] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("phone");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
    });
    const onTextChangeFirstName = (e) => setFirstName(e.target.value);
    const onTextChangeLastName = (e) => setLastName(e.target.value);
    const onTextChangeEmail = (e) => setEmailInput(e.target.value);
    const onTextChangePhone = (e) => setPhone(e.target.value);

    
    const handleSubmit = () => {
        console.log(emailInput);
        setIsSubmitted(true)
        if(emailInput === "" || phone === "" || firstname === "" || lastname === "" )
        {
            alert("Please fill all the required fields")
        }
        else if (!checkEmail(emailInput))
        {
            alert("Please enter a valid email address")

        }
        else
        {
            setSessionData({...sessionData, email: emailInput, firstName: firstname, lastName: lastname, phone:phone})
            navigate('/checkout', { state: {amount:amount, typeOfDonation:typeOfDonation, currency:currency,currencyCode:currencyCode,  pk: pk, sk:sk}})
        }

    }

    const checkEmail = (email) => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if ( re.test(email) ) {
            // this is a valid email address
            // call setState({email: email}) to update the email
            // or update the data in redux store.
            return true
        }
        else {
        return false

            // invalid email, maybe show an error to the user.

        }
    }

    useEffect(() => {
        // storing input name
        localStorage.setItem("firstname", JSON.stringify(firstname));
        localStorage.setItem("lastname", JSON.stringify(lastname));
        localStorage.setItem("emailInput", JSON.stringify(emailInput));
        localStorage.setItem("phone", JSON.stringify(phone));

        console.log(localStorage);
    }, [firstname,lastname,emailInput,phone]);

    // function handleSubmit(event) {
    //      event.preventDefault();
    //      const data = new FormData(event.target);
    //      console.log(data.get('email')); // reference by form input's `name` tag
    //     setSessionData({...sessionData, email: data.get('email'), firstName:data.get('firstname'), lastName: data.get('lastname')})
    //     navigate("/")
    //     setEmail(data.get('email'))
    //  }
    return (


        <>
          
            <div>
              <b>{state.typeOfDonation}</b>
            </div>
            <div>
               <b>{appData.currencySymbol}{state.amount}.00</b>
            </div>
            <Toolbar />

        
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justify="center"


                >

                    <Grid container xs={12} spacing={3} width={300}>
                        <Grid item xs={12}>
                        <b>{appData.titleMyDetails}</b>

                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                onChange={onTextChangeFirstName}
                                value={firstname}
                                error={(firstname === "") && isSubmitted}
                                id="outlined-basic firstname" label={appData.newBoxFirstname} variant="outlined"/>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                onChange={onTextChangeLastName}
                                value={lastname}
                                error={(lastname === "") && isSubmitted}
                                id="outlined-basic lastname" label={appData.newBoxSurname}  variant="outlined"/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                onChange={onTextChangeEmail}
                                value={emailInput}
                                error={(emailInput === "") && isSubmitted}
                                id="outlined-basic email" label={appData.placeholderEmail}  variant="outlined"/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                onChange={onTextChangePhone}
                                value={phone}
                                error={(phone === "") && isSubmitted}
                                id="outlined-basic phone" label={appData.placeholderPhone}  variant="outlined"/>
                        </Grid>
                        <Grid item xs={12}>
                            <Button fullWidth variant="contained" onClick={handleSubmit} style={{color:"white"}}>{appData.buttonSubmit}</Button>
                        </Grid>
                        {/*<label htmlFor="username">First Name</label>*/}
                        {/*<input id="firstname" name="firstname" type="text" />*/}

                        {/*<label htmlFor="lastname">Last Name</label>*/}
                        {/*<input id="lastname" name="lastname" type="text" />*/}

                        {/*<label htmlFor="email">Email</label>*/}
                        {/*<input id="email" name="email" type="email" />*/}
                        {/*<label htmlFor="phone">Phone</label>*/}
                        {/*<input id="phone" name="phone" type="text" />*/}


                        {/*<button>Send data!</button>*/}
                    </Grid>
                </Grid>


        </>
        // <Grid container justify="center"
        //       alignItems="center"
        //       justifyContent="center">
        //     <Grid item alignItems="center"
        //           justifyContent="center">
        //         <Box style={{width: "400px"}}>
        //             <Box>
        //                 <Image src="image/logo_signin.png" style={{width: "200px", height:"200px"}}/>
        //             </Box>
        //             <Box>
        //                 <Image src="image/image_boy_signin.png" style={{width: "400px"}}/>
        //             </Box>
        //
        //
        //         </Box>
        //
        //         <Box style={{textAlign:"center"}}>
        //             <GoogleLogin
        //
        //                 style={{width:"350px"}}
        //                 clientId="805552047047-55012l3fobguorrin8fe99skvav2h700.apps.googleusercontent.com"
        //                 buttonText="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sign In With Google&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
        //                 onSuccess={responseGoogle}
        //                 onFailure={responseGoogle}
        //                 cookiePolicy={'single_host_origin'}
        //             />
        //         </Box>
        //
        //     </Grid>
        //
        // </Grid>

    );

}