import * as React from 'react';
import PageTitle from "./widgets/PageTitle";
import Box from "@mui/material/Box";
import {Grid, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import ApiRouter from "./api/ApiRouter";
import {ReferenceDataContext} from "./utils/ReferenceDataContext";
import { moment } from 'moment';
import Typography from "@mui/material/Typography";
import {Image} from "mui-image";
import ProgressLoader from './widgets/ProgressLoader';
export default function MyDonations({appData})
{

    const [myDonations, setMyDonations] = useState([])
    const userId = localStorage.getItem("userId")


    useEffect(
        function effectFunction() {
            var method = "api/listuserdonations"
            const formData = new FormData();
            formData.append('userid', userId)
            ApiRouter.fetchDataPost(method, formData,(response) => {
                console.log(response,"MyDonations")
                try {
                    setMyDonations(response?.data)
                } catch (e) {
                    console.log("Exception","MyDonations")
                }
            }, (err) => {
                //error
                console.log("error","MyDonations")
            });

        },
        [userId]
    )

    return (
        <>
            <PageTitle title={appData.menuMyDonation}/>
            {myDonations !== null ? (
                     myDonations?.map((row, index) => (
                                <Grid container spacing={1} key={row.createddate} alignItems={"start"} padding={2}>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography gutterBottom variant="subtitle1" component="div" textAlign={"left"}>
                                                    <b>{row.currencycode}  {row.amount}</b> ({row.type})
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" textAlign={"left"}>
                                                    {row.createddate}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1" component="div">
                                                {row.paymenttype === "GooglePay" ?  <Image src="/image/gpay.png"/> :(
                                                    row.paymenttype === "ApplePay" ? <Image src="/image/apay.png"/> : (row.paymenttype)
                                                )}
                                            </Typography>
                                    </Grid>
                                </Grid>
                    ))
            ): <ProgressLoader />}
        </>
    );
}



export   function ConvertDate ({date})
{
    const t = date.split(/[- :]/);
    const d = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));
    console.log(d)
    return (
        <div> {d} </div>
    );
}