import * as React from 'react';
import Box from "@mui/material/Box";
import Image from "mui-image";
import {Button, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select} from "@mui/material";
import {useEffect, useState} from "react";
import ApiRouter from "./api/ApiRouter";
import ProgressLoader from "./widgets/ProgressLoader";

export default function CountrySettings({appData}) {
    const [countriesList, setCountriesList] = React.useState(null);
    const [selectedCountry, setSelectedCountry] = React.useState(() => {
        // getting stored value
        const saved = localStorage.getItem("countryId");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
    });
    const [selectedLanguage, setSelectedLanguage] = React.useState(() => {
        // getting stored value
        const saved = localStorage.getItem("languageId");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
    })
    console.log(selectedCountry)
    console.log(selectedCountry)

    const handleChangeCountry = (event) => {
        // storing input
        localStorage.setItem("countryId", JSON.stringify(event.target.value));
        setSelectedCountry(event.target.value);
    };

    const handleChangeLanguage = (event) => {
        // storing input
        localStorage.setItem("languageId", JSON.stringify(event.target.value));
        setSelectedLanguage(event.target.value);

    };

    useEffect(
        function effectFunction() {
            const method = "/api/listcountriesandlanguages";
            ApiRouter.fetchDataPost(method, [], (response) => {
                setCountriesList(response)
                console.log(response, "MyDonations")

            }, (err) => {
                //error
                console.log("error", "MyDonations")
            });

        },
        []
    )
    const handleSubmit = () => {
        window.location.reload(false);
    }
    return (
        <>
            {countriesList == null ? <ProgressLoader/> :
                <Grid container justify="center" spacing={3} p={5} maxWidth="sm">
                    <Grid item xs={12}>
                        
                        <FormControl fullWidth>
                            <InputLabel id="country-select-label"> {appData.placeholderSelectCountry}</InputLabel>
                            <Select
                                labelId="country-select-label"
                                id="country-select"
                                value={selectedCountry}
                                label="Country"
                                input={<OutlinedInput label="Country"/>}
                                onChange={handleChangeCountry}
                            >
                                {countriesList?.data.map(item => {
                                    return <MenuItem value={item}>{item.name}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="language-select-label">{appData.placeholderSelectLanguage}</InputLabel>
                            <Select
                                labelId="language-select-label"
                                id="language-select"
                                value={selectedLanguage}
                                label="Language"
                                input={<OutlinedInput label="Language"/>}
                                onChange={handleChangeLanguage}
                            >
                                {selectedCountry?.languages?.map(item => {
                                    return <MenuItem value={item}>{item.name}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Button variant="contained" onClick={handleSubmit} style={{
                                width: '100%',
                                height: 50,
                                marginTop: 10,
                                color: "#FFF",
                                fontSize: 12,
                                lineHeight: 1,
                                fontWeight: "bold"
                            }}>
                                {appData.titleButtonSave}
                            </Button>
                        </FormControl>
                    </Grid>
                </Grid>
            }
        </>
    )
        ;
}