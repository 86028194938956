import * as React from 'react';
import {Link, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper} from "@mui/material";
import PageTitle from "./widgets/PageTitle";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import {Email, Language, LocationCity, LocationOn, MapRounded, Phone, Web} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
export default function ContactUs({appData})
{
    return (
        <Box>
                <Paper elevation={0} style={{width: "100%"}}>
                    <img src='/image/home_banner.png' style={{width: "100%"}}/>
                </Paper>

            <Box>
                <List>

                    <ListItem disablePadding component={Link}>
                        <Typography variant={"h5"} >&nbsp;&nbsp;{appData.contactUsTitle} </Typography>
                    </ListItem>
                    <ListItem disablePadding component={Link}>
                        <ListItemButton>
                            <ListItemIcon>
                                <Phone color={"primary"}/>
                            </ListItemIcon>
                            <ListItemText primary={appData?.contactUsPhone} color={"primary"}/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding component={Link} >
                        <ListItemButton>
                            <ListItemIcon>
                                <Email color={"primary"}/>
                            </ListItemIcon>
                            <ListItemText primary={appData?.contactUsEmail} color={"primary"}/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding   component={Link}  onClick = {
                        () =>

                        window.open("https://" + appData?.contactUsWebsite, '_blank')
                    } >
                        <ListItemButton>
                            <ListItemIcon>
                                <Language color={"primary"}  />
                            </ListItemIcon>
                            <ListItemText primary={appData?.contactUsWebsite} color={"primary"}/>
                        </ListItemButton>
                    </ListItem>
                    {/*<ListItem disablePadding  component={Link}>*/}
                    {/*    <ListItemButton>*/}
                    {/*        <ListItemIcon>*/}
                    {/*            <LocationOn color={"primary"}/>*/}
                    {/*        </ListItemIcon>*/}
                    {/*        <ListItemText  component={Link} to={appData?.contactusaddress} primary={appData?.contactusaddress} color={"primary"}/>*/}
                    {/*    </ListItemButton>*/}
                    {/*</ListItem>*/}

                </List>
            </Box>
        </Box>

    );
}