import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {MemoryRouter} from "react-router-dom";

ReactDOM.render(
    <MemoryRouter>
        <App />
    </MemoryRouter>, document.getElementById( 'root' ) );
reportWebVitals();
