import * as React from 'react';
import Box from "@mui/material/Box";
import Image from "mui-image";
import {AppBar, Button, CssBaseline, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import GiftMyDonation from './GiftMyDonation';
import { TryRounded } from '@mui/icons-material';

export default function ThankYou({props,appData,donationid}) {
    const navigate = useNavigate();
    const amount = Number(props.amount).toFixed(2)
    const [isGiftMyDonation, setIsGiftMyDonation] = useState(false);
    const country = JSON.parse(localStorage.getItem("countryId"))
    // useEffect(() => {
    //     setInterval(() => {
    //         window.location = window.location.pathname
    //     }, 5000)
    // }, [])

    const handleSubmit = () => {
        //navigate('/giftmydonation', { state: {donationid:props.donationid, appData:appData}})
        setIsGiftMyDonation(true)
    }

    return (
        <>
            <AppBar
                elevation={0}
                color={"transparent"}
                style={{ background: "#FFFFFF" }}
            >
                <Toolbar>
                    <Image src="/image/header_logo.png" style={{
                        float: 'none',
                        width: '130px',
                        marginLeft: 'auto',
                        marginRight: 'auto',

                    }} />
                </Toolbar>
            </AppBar>
            <CssBaseline />
            <div>
                <b>{appData?.titleGiftAid}</b>
            </div>
            <Toolbar />
         {isGiftMyDonation ? <GiftMyDonation appData={appData} donationid={donationid}/> : 
        <Grid container justify="center"
              alignItems="center"
              justifyContent="center">
            <Grid item alignItems="center"
                  justifyContent="center">
                <Box style={{width: "400px"}}>
                    <Box>
                        <img src='/image/home_banner.png' style={{width: "100%"}}/>
                    </Box>
                </Box>
                <Box style={{textAlign: "center"}} p={3}>
                    <Typography variant={"h5"}>
                        {appData.titleAcknowledgement}
                    </Typography>
                    <Typography variant={"h5"}>
                        {appData.descriptionAcknowledgement}
                    </Typography>

                </Box>
                <Box style={{textAlign: "center"}}>
                    <Typography variant={"h5"}>
                        {appData.currencySymbol}{amount}
                    </Typography>
                </Box>
                <Box style={{textAlign: "center"}}>
                    <Typography variant={"h5"}>
                        {props.type}
                    </Typography>
                </Box>
                <Toolbar/>
                <Grid item xs={12} p={3}>
                <Button style={{width: 200,color: "white"}} variant="contained" onClick={() =>
                    window.location = window.location.pathname
                } >{appData.buttonContinue}</Button>
                </Grid>
               {country.code == "GB" ?
                <Grid item xs={12} p={3}>
                <Button style={{width: 200,color: "white"}} variant="contained" onClick={
                    handleSubmit
                } >{appData.buttonTitle}</Button>
                  </Grid> : <></>}
            </Grid>

        </Grid>
}
</>
    );

}