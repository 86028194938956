import React, {useState, useEffect, useContext} from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import {useLocation} from "react-router-dom";
import ApiRouter from "./api/ApiRouter";
import {ReferenceDataContext} from "./utils/ReferenceDataContext";
import Toolbar from "@mui/material/Toolbar";
import {CircularProgress} from "@mui/material";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
var stripePromise = null

export default function PrepareCheckOut() {
  const [clientSecret, setClientSecret] = useState("");

    const { state } = useLocation();
    const finalAmount = state.amount 
    const pk = state.pk
    const sk = state.sk
    const currency = state.currency
    const currencyCode = state.currencyCode
    console.log(finalAmount)

    const {sessionData} = useContext(ReferenceDataContext)



    if(pk !== null)
    {
        stripePromise = loadStripe(pk);
    }
   useEffect(
        function effectFunction() {
            if(sk == null)
            {
                return
            }
            var method = "/donate2/api/index.php?id=processpayment"
            var params = {
                name : sessionData.firstName + " " + sessionData.lastName,
                email: sessionData.email,
                phone:sessionData.phone,
                description:state.typeOfDonation,
                apiKey: sk,
                amount: finalAmount,
                currecycode: currencyCode,
            }
            ApiRouter.fetchDataPost(method, params, (response) => {
                console.log(response, "CheckoutRes")
                setClientSecret(response?.clientsecret)

            }, (err) => {
                //error
                console.log("error", "CheckoutResError")
            });

        },
        [sk]
    )


  const appearance = {
    theme: 'stripe',
  };


  const options = {
    clientSecret,
    appearance,
      fields: {
          billingDetails: {
              email: 'auto',
              address: {
                  line1: 'never',
                  city: 'never',
                  state: 'never',
                  country: 'never',
                  postalCode: 'never'
              }
          }
      }
  };

  return (
    <div className="App" style={{padding:10}}>
        <div>
             <b>{state.typeOfDonation}</b>
        </div>
        <div>
        <b>{currency}{state.amount}.00</b>
        </div>


        <Toolbar />

        {clientSecret === "" ? <CircularProgress /> : null}
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm/>
        </Elements>
      ) }

    </div>
  );
}